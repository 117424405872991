
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "LineDemo",
  setup() {
    const leftList = ["元素1", "元素2", "元素3"];
    const rightList = ["目标元素1", "目标元素2", "目标元素3"];
    const startX = ref(0);
    const startY = ref(0);
    const startIdx = ref(-1);
    const endIdx = ref(-1);
    const linePath = ref<SVGPathElement | null>(null);

    const startLine = (idx: number, event: MouseEvent) => {
      startX.value = event.clientX;
      startY.value = event.clientY;
      startIdx.value = idx;
      endIdx.value = -1;
      linePath.value?.setAttribute("d", "");
    };

    const drawLine = (event: MouseEvent) => {
      if (startIdx.value !== -1 && endIdx.value === -1) {
        const x1 = startX.value;
        const y1 = startY.value;
        const x2 = event.clientX ?? 0;
        const y2 = event.clientY ?? 0;
        const path = `M${x1},${y1} L${x2},${y2}`;
        linePath.value?.setAttribute("d", path);
      }
    };

    const endLine = (event: MouseEvent) => {
      if (startIdx.value !== -1 && endIdx.value === -1) {
        const elements = document.querySelectorAll(".right > div");
        for (let i = 0; i < elements.length; i++) {
          const rect = elements[i].getBoundingClientRect();
          const x = event.clientX ?? 0;
          const y = event.clientY ?? 0;
          if (
            x >= rect.left &&
            x <= rect.right &&
            y >= rect.top &&
            y <= rect.bottom
          ) {
            endIdx.value = i;
            const x1 = startX.value;
            const y1 = startY.value;
            const x2 = rect.left + rect.width / 2;
            const y2 = rect.top + rect.height / 2;
            const path = `M${x1},${y1} L${x2},${y2}`;
            linePath.value?.setAttribute("d", path);
            break;
          }
        }
        if (endIdx.value === -1) {
          linePath.value?.setAttribute("d", "");
        }
      }
    };

    return {
      leftList,
      rightList,
      startX,
      startY,
      startIdx,
      endIdx,
      linePath,
      startLine,
      drawLine,
      endLine,
    };
  },
});
